import React from 'react';

function CardFooter({ children }) {
  return (
    <>
      <hr className="border border-gray-200" />
      <div className="px-5 py-3">
        {children}
      </div>
    </>
  );
}

export default CardFooter;
