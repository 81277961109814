import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../../hooks/use-translate';
import { Heading, Modal } from '../../../../components';

function RecommendationModal({
  isOpen,
  toggle,
  title,
  content,
}) {
  const { t, trans } = useTranslate([
    content,
  ]);

  return (
    <Modal open={isOpen} onClose={toggle}>
      <div>
        <Heading variant="h2">{t(title)}</Heading>
        <div className="flex flex-col gap-6 mt-4">
          <p>{trans[content]}</p>
        </div>
      </div>
    </Modal>
  );
}

RecommendationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default RecommendationModal;
