import { useEffect } from 'react';

const TIMEOUT_HOURS = 8;
const TIMEOUT = 1000 * 60 * 60 * TIMEOUT_HOURS;

function useTTL() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, TIMEOUT);

    return () => clearTimeout(timeout);
  }, []);
}

export default useTTL;
