import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  HeroLanding,
  Type,
} from '../../../../components';
import { LayoutLanding } from '../../../../layouts';
import useTranslate from '../../../../hooks/use-translate';
import LanguagesModal from '../languages-modal';

import ImgIconFlagUK from '../../../../assets/icon-flag-uk.png';
import ImgIconFlagFR from '../../../../assets/icon-flag-fr.png';
import ImgIconFlagJP from '../../../../assets/icon-flag-jp.png';
import ImgIconFlagTR from '../../../../assets/icon-flag-tr.png';
import ImgIconFlagBE from '../../../../assets/icon-flag-be.png';
import ImgIconFlagDE from '../../../../assets/icon-flag-de.png';
import ImgIconFlagIT from '../../../../assets/icon-flag-it.png';
import ImgIconFlagHK from '../../../../assets/icon-flag-hk.png';
import ImgIconFlagES from '../../../../assets/icon-flag-es.png';
import ImgIconFlagMX from '../../../../assets/icon-flag-mx.png';
import ImgIconFlagTH from '../../../../assets/icon-flag-th.png';
import ImgIconFlagCH from '../../../../assets/icon-flag-ch.png';
import ImgIconFlagPH from '../../../../assets/icon-flag-ph.png';
import ImgIconGlobal from '../../../../assets/icon-global.png';

const languages = [
  {
    name: 'Global (English)',
    icon: ImgIconGlobal,
    code: 'en',
    url: '/',
  },
  {
    name: 'UK (English)',
    icon: ImgIconFlagUK,
    code: 'en-GB',
    url: '/en-GB',
  },
  {
    name: 'France (Français)',
    icon: ImgIconFlagFR,
    code: 'fr',
    url: '/fr',
  },
  {
    name: '日本 (Japan)',
    icon: ImgIconFlagJP,
    code: 'ja',
    url: '/ja',
  },
  {
    name: 'Türkiye (Türkçe)',
    icon: ImgIconFlagTR,
    code: 'tr',
    url: '/tr',
  },
  {
    name: 'België (Nederlands)',
    icon: ImgIconFlagBE,
    code: 'nl-BE',
    url: '/nl-BE',
  },
  {
    name: 'Belgique (Français)',
    icon: ImgIconFlagBE,
    code: 'fr-BE',
    url: '/fr-BE',
  },
  {
    name: 'Deutschland (Deutsch)',
    icon: ImgIconFlagDE,
    code: 'de',
    url: '/de',
  },
  {
    name: 'Italia (Italiano)',
    icon: ImgIconFlagIT,
    code: 'it',
    url: '/it',
  },
  {
    name: '香港 (Hong Kong)',
    icon: ImgIconFlagHK,
    code: 'hk',
    url: '/hk',
  },
  {
    name: 'España (Español)',
    icon: ImgIconFlagES,
    code: 'es',
    url: '/es',
  },
  {
    name: 'México(Español)',
    icon: ImgIconFlagMX,
    code: 'es-MX',
    url: '/es-MX',
  },
  {
    name: 'ประเทศไทย (Thailand)',
    icon: ImgIconFlagTH,
    code: 'th',
    url: '/th',
  },
  {
    name: 'Suisse (Français)',
    icon: ImgIconFlagCH,
    code: 'fr-CH',
    url: '/fr-CH',
  },
  {
    name: 'Schweiz (Deutsch)',
    icon: ImgIconFlagCH,
    code: 'de-CH',
    url: '/de-CH',
  },
  {
    name: 'Svizzera (Italiano)',
    icon: ImgIconFlagCH,
    code: 'it-CH',
    url: '/it-CH',
  },
  {
    name: 'Philippines (English)',
    icon: ImgIconFlagPH,
    code: 'en-PH',
    url: '/en-PH',
  },
];

function LandingGlobal() {
  const { trans, t, i18n } = useTranslate([
    'welcome.title',
    'welcome.intro',
  ]);
  const [languagesModalIsOpen, setLanguagesModalIsOpen] = useState(false);

  const toggleLanguagesModal = () => {
    setLanguagesModalIsOpen((isOpen) => !isOpen);
  };

  return (
    <LayoutLanding hero={<HeroLanding />}>
      <LanguagesModal
        languages={languages}
        active={i18n.language}
        isOpen={languagesModalIsOpen}
        toggle={toggleLanguagesModal}
      />
      <div className="flex flex-col gap-8 mx-auto md:gap-16 lg:gap-12 lg:max-w-[500px]">
        <h1 className="text-2xl font-bold text-center">
          {trans['welcome.title']}
        </h1>
        <Type variant="lead">{trans['welcome.intro']}</Type>
        <ButtonGroup>
          <Button type="link" to="consent" variant="primary" id="start">{t('welcome.cta')}</Button>
          <Button
            type="button"
            variant="outline-primary"
            onClick={toggleLanguagesModal}
            id="change_language"
          >
            {t('welcome.change_language')}
          </Button>
        </ButtonGroup>
      </div>
    </LayoutLanding>
  );
}

export default LandingGlobal;
