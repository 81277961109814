import { redirect } from 'react-router';
import * as client from 'openid-client';
import API from '../../../../services/api';

const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

// Loader for the callback route. Performs an authorization code grant and saves
// tokens in local storage.
async function loader() {
  if (requiresAuth === undefined || requiresAuth === 'false') {
    return redirect('/');
  }

  const org = window.sessionStorage.getItem('org');
  const codeVerifier = window.sessionStorage.getItem('code_verifier');
  const nonce = window.sessionStorage.getItem('nonce');
  let server;
  let clientId;
  let config;
  let orgId;

  // By default we use the auth server details for AXA from the env. Because
  // the app has been designed in a single tenant manner each AXA entity has
  // its own set of environment variables for its own specific deployment. If
  // an `org` param was set we use a different auth server. This is to allow
  // development and test environments to operate independently of AXA.
  switch (org) {
    case 'mindstep':
      server = new URL(import.meta.env.VITE_AUTH_HOST_MINDSTEP);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_MINDSTEP;
      orgId = import.meta.env.VITE_AUTH_ORG_ID_MINDSTEP;
      break;
    default:
      server = new URL(import.meta.env.VITE_AUTH_HOST_AXA);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_AXA;
  }

  const data = client.discovery(server, clientId)
    .then((authConfig) => {
      const url = new URL(window.location.href);
      const params = {
        pkceCodeVerifier: codeVerifier,
        expectedNonce: nonce,
        idTokenExpected: true,
      };

      if (orgId) {
        params.tokenEndpointParameters = {
          organization: import.meta.env.VITE_AUTH_ORG_ID_MINDSTEP,
        };
      }

      config = authConfig;

      return client.authorizationCodeGrant(config, url, params);
    })
    .then((tokens) => {
      const claims = tokens.claims();

      window.localStorage.setItem('access_token', tokens.access_token);
      window.localStorage.setItem('refresh_token', tokens.refresh_token);
      window.localStorage.setItem('id_token', tokens.id_token);
      window.localStorage.setItem('sub', claims.sub);
      API.configure();

      return {
        location: '/',
      };
    });

  return {
    data,
  };
}

export default loader;
