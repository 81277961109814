import { Amplify } from 'aws-amplify';
import { get, post } from 'aws-amplify/api';

const API_NAME = import.meta.env.VITE_AWS_API_NAME;
const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

const amplifyConfig = {
  API: {
    REST: {
      [API_NAME]: {
        endpoint: import.meta.env.VITE_AWS_APIGATEWAY_URL,
      },
    },
  },
};

const amplifyOptions = {
  API: {
    REST: {},
  },
};

const API = {
  configure() {
    if (requiresAuth === 'true') {
      const accessToken = window.localStorage.getItem('access_token');

      amplifyOptions.API.REST.headers = async () => ({
        Authorization: `Bearer ${accessToken}`,
      });
    }

    Amplify.configure(amplifyConfig, amplifyOptions);
  },

  async post(endpoint, options = {}) {
    const operation = post({
      apiName: options.apiName || API_NAME,
      path: endpoint,
      options,
    });

    const response = await operation.response;
    const body = await response.body.json();

    return body;
  },

  async get(endpoint, options = {}) {
    const operation = get({
      apiName: options.apiName || API_NAME,
      path: endpoint,
      options,
    });

    const response = await operation.response;
    const body = await response.body.json();

    return body;
  },
};

API.configure();
export default API;
