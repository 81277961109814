import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const variantStyles = {
  h1: 'text-2xl text-gray-900 font-bold',
  h2: 'text-xl text-gray-900 font-bold',
  h3: 'text-sm text-gray-800 font-semibold uppercase tracking-[1px]',
  display5: 'text-2xl lg:text-3xl text-gray-900 font-bold',
};

function Heading({
  children,
  className,
  variant: Component,
  ...props
}) {
  const classes = classnames(variantStyles[Component], className);

  return <Component className={classes} {...props}>{children}</Component>;
}

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.node.isRequired,
};

export default Heading;
