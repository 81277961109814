import { useMatches, useParams } from 'react-router';
import API from '../../services/api';
import useStore from '../../store';

const Actions = {
  PAGE_LOAD: 'page_load',
  ANSWER: 'answer',
};

function useReport() {
  const { lang } = useParams();
  const matches = useMatches();

  // Configure the reporter function. Components can use this to report their
  // own events.
  const report = async (data) => {
    const match = matches[matches.length - 1];
    const key = match?.handle?.key?.(match?.params ?? {});
    const { session } = useStore.getState();
    const payload = {
      action: data.action,
      locale: lang,
      page: key,
      session,
    };

    try {
      await API.post('/report', {
        body: payload,
      });
    } catch (err) {
      // TODO: report error somewhere useful.
    }
  };

  // Expose reporter function.
  return {
    Report: {
      Actions,
    },
    report,
  };
}

export default useReport;
