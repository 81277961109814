import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const commonStyles = 'text-gray-900';
const variantStyles = {
  default: '',
  lead: 'text-xl',
  'lead-semibold': 'text-xl font-semibold',
  small: 'text-xs',
};

function Type({ children, className, variant = 'default' }) {
  const classes = classnames(commonStyles, variantStyles[variant], className);

  return (
    <p className={classes}>{children}</p>
  );
}

Type.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'default',
    'lead',
    'lead-semibold',
    'small',
  ]),
};

export default Type;
