import React from 'react';
import classnames from 'classnames';

const commonClasses = 'w-4 h-4 accent-axa-blue-500 appearance-none rounded-full border border-gray-600 transition-all peer';

function Radio({ className, ...props }) {
  const classes = classnames(commonClasses, className);

  return (
    <div className="relative flex">
      <input type="radio" className={classes} {...props} />
      <div className="peer-checked:bg-axa-blue-500 absolute top-0 left-0 ml-1 mt-1 rounded-full w-2 h-2" />
    </div>
  );
}

export default Radio;
