import React from 'react';
import { Heading } from '../../../../components';

function Item({ title, content }) {
  const list = Array.isArray(content) ? content : [content];

  return (
    <div>
      <Heading variant="h3" className="!text-axa-blue-500 uppercase">{title}</Heading>
      {list.map((c) => <p className="mt-3">{c}</p>)}
    </div>
  );
}

export default Item;
