import useStore from '../../../../store';
import screeningLoader from '../../../_loaders/screening';

// Root screening loader. Clears any existing responses from the store to
// ensure the set submitted on completion only contains the relevant responses
// and then delegates to the common screening loader.
async function loader(args) {
  useStore.getState().clearResponses();

  return screeningLoader(args);
}

export default loader;
