import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, CardBody, CardFooter } from '../../../../components';
import useReport from '../../../../hooks/use-report';
import IconExpandMore from '../../../../assets/icon-expand-more.svg?react';

function ComponentCard({
  title,
  cta,
  explain,
  score,
  id,
}) {
  const { report } = useReport();
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded((expanded) => !expanded);
    report({
      action: `toggle[${id}]`,
    });
  };
  const toggleClasses = classnames('inline transition-all fill-axa-blue-500', {
    'rotate-180': expanded,
  });
  const scoreClasses = classnames('font-semibold', {
    'text-axa-sienna-400': score < 40,
    'text-axa-yellow-100': score >= 40 && score < 60,
    'text-axa-green-100': score >= 60 && score < 80,
    'text-axa-green-500': score >= 80,
  });
  const powerbarClasses = classnames('rounded-full h-1.5', {
    'bg-axa-sienna-400': score < 40,
    'bg-axa-yellow-100': score >= 40 && score < 60,
    'bg-axa-green-100': score >= 60 && score < 80,
    'bg-axa-green-500': score >= 80,
  });

  return (
    <div className="w-full md:max-w-[336px] lg:max-w-[432px]">
      <Card>
        <CardBody>
          <div className="flex items-center justify-between">
            <p className="font-bold text-gray-900 text-lg">
              {title}
            </p>
            <div className="flex items-center gap-2">
              <p className={scoreClasses}>{score}</p>
              <div className="rounded-full bg-gray-200 w-20 h-1.5">
                <div className={powerbarClasses} style={{ width: `${score}%` }} />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <button type="button" onClick={toggle} className="text-axa-blue-500 font-semibold flex justify-between w-full">
            {cta}
            <IconExpandMore className={toggleClasses} />
          </button>
          {expanded && (
            <p className="mt-3">{explain}</p>
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

ComponentCard.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  explain: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

export default ComponentCard;
