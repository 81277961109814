import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import httpBackend from 'i18next-http-backend';

export const validLocales = {
  'en-gb': {
    code: 'en-GB',
  },
  fr: {
    code: 'fr',
  },
  ja: {
    code: 'ja',
  },
  tr: {
    code: 'tr',
  },
  'nl-be': {
    code: 'nl-BE',
  },
  'fr-be': {
    code: 'fr-BE',
  },
  de: {
    code: 'de',
  },
  it: {
    code: 'it',
  },
  hk: {
    code: 'hk',
  },
  'zh-hans-hk': {
    redirect: 'hk',
  },
  es: {
    code: 'es',
  },
  'es-mx': {
    code: 'es-MX',
  },
  th: {
    code: 'th',
  },
  'fr-ch': {
    code: 'fr-CH',
  },
  'de-ch': {
    code: 'de-CH',
  },
  'it-ch': {
    code: 'it-CH',
  },
  'en-ph': {
    code: 'en-PH',
  },
};

i18n
  .use(httpBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-GB': ['en'],
      'fr-BE': ['fr', 'en'],
      'nl-BE': ['nl', 'en'],
      'de-CH': ['de', 'en'],
      'fr-CH': ['fr', 'en'],
      'it-CH': ['it', 'en'],
      hk: ['zh-Hans-HK'],
      default: ['en'],
    },
    detection: {
      order: ['path'],
      caches: [],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['a', 'br', 'strong', 'i', 'b', 'small'],
    },
  });

export default i18n;
