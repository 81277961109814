import Config from '../../config';

function useScreening({
  Screening,
  sectionIndex,
  questionIndex,
}) {
  const screening = Screening || Config.Screening.Global;
  const si = parseInt(sectionIndex, 10);
  const qi = parseInt(questionIndex, 10);
  const section = screening.Sections[si - 1];
  const question = section.questions[qi - 1];

  return {
    sections: screening.Sections,
    sectionIndex: si,
    questionIndex: qi,
    section,
    question,
  };
}

export default useScreening;
