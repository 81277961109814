import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Heading,
  Toggle,
} from '../../../../components';
import Summary from '../../components/summary';
import SubscoreCard from '../../components/subscore-card';
import Hero from '../../components/hero';
import RecommendationModal from '../../components/recommendation-modal';
import BESupport from '../../components/be-support';
import useResults from '../../hooks/use-results';
import useReport from '../../../../hooks/use-report';
import getPath from '../../../../utils/path';
import { ScreeningConfig } from '../../../../services/config';

function getScoreCategory(score, t) {
  if (score < 43) {
    return [0, t('results.category.struggling.title'), 'results.category.struggling.content'];
  }

  if (score >= 43 && score < 57) {
    return [1, t('results.category.languishing.title'), 'results.category.languishing.content'];
  }

  if (score >= 57 && score < 68) {
    return [2, t('results.category.getting_by.title'), 'results.category.getting_by.content'];
  }

  return [3, t('results.category.flourishing.title'), 'results.category.flourishing.content'];
}

function Results() {
  const { report } = useReport();
  const { results } = useResults();
  const { assessmentId, lang } = useParams();
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [recommendationModalIsOpen, setRecommendationModalIsOpen] = useState(false);
  const config = assessmentId !== undefined
    ? ScreeningConfig[assessmentId]?.results
    : ScreeningConfig.dmhi.results;
  const { t } = useTranslation();
  const goodScores = [];
  const supportScores = [];
  const toggleRecommendationsModal = () => {
    setRecommendationModalIsOpen((isOpen) => !isOpen);
  };
  const selectedFactor = selectedResult !== null
    ? config.factors.find((factor) => factor.id === selectedResult)
    : null;

  const handleToggle = (i) => {
    report({
      action: `toggle[${i}]`,
    });
    setSelectedTab(i);
  };

  // Find the lowest scoring factors for recommendation purposes.
  const recommendationFactors = config.factors
    .map((factor) => [factor.key, results[factor.key]])
    .sort(([, a], [, b]) => (a > b ? 1 : -1))
    .filter(([, score]) => score <= config.recommendationThreshold)
    .slice(0, config.recommendationLimit)
    .map(([factor]) => factor);

  let handleClick;
  let scoreCategory;
  let label;
  let desc;

  if (assessmentId === 'dmhi' || assessmentId === undefined) {
    [scoreCategory, label, desc] = getScoreCategory(results?.MHI, t);
  } else {
    desc = config.desc;
  }

  if (config.recommendationType === 'modal') {
    handleClick = (e) => {
      e.preventDefault();
      setSelectedResult(e.target.id);
      setRecommendationModalIsOpen(true);
    };
  }

  config.factors.forEach((factor) => {
    let score = results[factor.key];

    if (config.percentage) {
      score *= config.percentageFactor;
    }

    const bounds = factor.bounds ?? config.bounds;
    const props = {
      key: factor.id,
      type: factor.id,
      title: t(factor.title),
      subtitle: t(factor.subtitle),
      icon: factor.icon,
      isPercentage: config.percentage,
      onClick: handleClick,
      bounds,
      score,
    };

    if (recommendationFactors.includes(factor.key)) {
      props.cta = t(factor.cta);
    }

    if (score > config.boundary) {
      if (factor.ctaGood !== undefined) {
        props.cta = t(factor.ctaGood);
      }

      if (factor.ctaGoodLink !== undefined) {
        props.link = factor.ctaGoodLink;
      }

      goodScores.push(<SubscoreCard {...props} />);
    } else {
      if (factor.ctaLink !== undefined) {
        props.link = factor.ctaLink;
      }

      supportScores.push(<SubscoreCard {...props} />);
    }
  });

  return (
    <div className="w-full mx-auto">
      <RecommendationModal
        isOpen={recommendationModalIsOpen}
        toggle={toggleRecommendationsModal}
        title={selectedFactor?.title}
        content={selectedFactor?.recommendation}
      />
      <div className="w-full md:bg-gray-200 md:h-[370px]">
        <Hero />
      </div>
      <div className="container mx-auto px-4 flex flex-col mt-28 max-w-[736px] lg:pb-[200px] md:mt-[-100px]">
        <Summary
          title={t(config.title)}
          score={results?.[config.key] ?? null}
          scoreCategory={scoreCategory}
          label={label}
          desc={desc}
          explain="results.mhi.explain.content"
          cta={t('results.mhi.explain.cta')}
          expand={config.expand}
        />
        <div className="flex flex-col gap-12 mt-12">
          {config.actionsHeader?.map?.((action) => {
            switch (action.type) {
              case 'link':
                // TODO: Support for multiple buttons within a group.
                return (
                  <ButtonGroup>
                    <Button
                      type="link"
                      to={getPath(lang, action.props.to)}
                      id={action.props.id}
                      variant={action.props.variant === undefined ? 'primary' : action.props.variant}
                    >
                      {t(action.content)}
                    </Button>
                  </ButtonGroup>
                );
              case 'toggle':
                return (
                  <div className="flex justify-center">
                    <Toggle
                      items={action.content.map((item) => t(item))}
                      active={selectedTab}
                      onChange={handleToggle}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
          {selectedTab === 0 ? (
            <>
              {goodScores.length > 0 && (
                <div className="flex flex-col gap-4">
                  <Heading variant="h2">{t('results.good')}</Heading>
                  <div className="flex flex-col flex-wrap gap-4 md:flex-row">
                    {goodScores}
                  </div>
                </div>
              )}
              {supportScores.length > 0 && (
                <div className="flex flex-col gap-4">
                  <Heading variant="h2">{t('results.support')}</Heading>
                  <div className="flex flex-col flex-wrap gap-4 md:flex-row md:flex-wrap">
                    {supportScores}
                  </div>
                </div>
              )}
            </>
          ) : <BESupport />}
        </div>
        <ButtonGroup className="mt-8 md:mt-12">
          {config.actionsFooter?.map?.((action) => (
            <Button
              type="link"
              to={getPath(lang, action.props.to)}
              id={action.props.id}
              variant={action.props.variant === undefined ? 'primary' : action.props.variant}
            >
              {t(action.content)}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
}

export default Results;
