import React from 'react';
import { Form } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Heading,
} from '../../../../components';

function ScreeningComplete() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 mb-12 lg:mb-0 lg:min-h-[955px]">
      <div className="flex flex-col pt-4 relative">
        <div className="bg-[url('/bg-hero-complete-1.png')] bg-contain bg-no-repeat bg-center h-[200px] lg:h-[855px] lg:w-[801px] lg:absolute right-0 top-[100px]" />
        <Card className="lg:max-w-[577px] lg:mx-auto lg:mt-[245px] z-10">
          <CardBody className="p-12 text-center">
            <Heading variant="h1" className="text-center">{t('screening.global.complete.title')}</Heading>
            <p className="mt-8">{t('screening.global.complete.content')}</p>
            <Form method="post">
              <ButtonGroup>
                <Button type="submit" variant="primary" id="view_results" className="w-full mt-8">
                  {t('screening.global.complete.cta')}
                </Button>
              </ButtonGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export { default as screeningCompleteAction } from './action';
export default ScreeningComplete;
