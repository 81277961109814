import API from '../../../services/api';

// A shared loader module to retrieve screenings available to the current tenant.
async function screeningsLoader() {
  const entity = import.meta.env.VITE_DMHI_ENTITY;

  if (entity === 'global' || entity === undefined) {
    return null;
  }

  let res;

  try {
    res = await API.get('/screenings', {
      queryParams: {
        entity: import.meta.env.VITE_DMHI_ENTITY,
      },
    });
  } catch (err) {
    return null;
  }

  return {
    screenings: res?.data?.screenings,
  };
}

export default screeningsLoader;
