import API from '../../../../services/api';
import screeningsLoader from '../../../_loaders/screenings';

// Loader for the history route. Retrieves both screenings available to the
// current tenant and history available to the authenticated user. Screenings
// available to the current tenant are required to populate the history page
// correctly in cases where the user has no history for any of the screening
// types.
async function loader() {
  let screenings;
  let history;

  try {
    [screenings, history] = await Promise.all([
      screeningsLoader(),
      API.get('/history'),
    ]);
  } catch (err) {
    return null;
  }

  return {
    history: history?.data,
    screenings: screenings?.screenings,
  };
}

export default loader;
