import React from 'react';
import Spinner from '../spinner';

// A full page overlay with a spinner to indicate something is loading.
function Loading() {
  return (
    <>
      <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center z-[1000]">
        <Spinner />
      </div>
      <div className="fixed w-full h-full top-0 left-0 bg-white opacity-75 z-50" />
    </>
  );
}

export default Loading;
