import React, { useState } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import {
  Card,
  CardBody,
  Heading,
  PowerBar,
} from '../../../../components';
import useReport from '../../../../hooks/use-report';
import IconExpandMore from '../../../../assets/icon-expand-more.svg?react';

function Summary({
  title,
  score,
  scoreCategory,
  label,
  desc,
  cta,
  explain,
  expand,
}) {
  const { report } = useReport();
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded((expanded) => !expanded);
    report({
      action: 'toggle[summary]',
    });
  };
  const toggleClasses = classnames('inline transition-all fill-axa-blue-500', {
    'rotate-180': expanded,
  });

  return (
    <Card className="flex flex-col z-20">
      <CardBody>
        <Heading variant="h1" className="text-center">{title}</Heading>
        {score >= 43 ? (
          <Heading variant="h2" className="text-center !text-axa-blue-100 mt-3">{score}</Heading>
        ) : null}
        {scoreCategory !== undefined ? <PowerBar active={scoreCategory} className="mt-3" /> : null}
        {label && <h3 className="text-center text-gray-900 font-semibold mt-3">{label}</h3>}
        <p className="text-xl text-gray-900 mt-5"><Trans i18nKey={desc} /></p>
        {expand === true ? (
          <>
            <hr className="mt-6 border border-gray-200" />
            <button type="button" onClick={toggle} className="text-axa-blue-500 font-semibold mt-5 w-full flex justify-between">
              {cta}
              <IconExpandMore className={toggleClasses} />
            </button>
          </>
        ) : null}
        {expanded && (
          <p className="mt-3">
            <Trans
              i18nKey={explain}
              components={{
                Link: <a className="text-axa-blue-500 underline" target="_blank" />,
              }}
            />
          </p>
        )}
      </CardBody>
    </Card>
  );
}

export default Summary;
