import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconClose from '../../assets/icon-close.svg?react';

function Modal({ open = false, onClose, children }) {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEsc);

    return () => document.removeEventListener('keydown', handleEsc);
  });

  const stopPropagation = (e) => e.stopPropagation();
  const classes = classnames('fixed flex justify-center items-center top-0 left-0 bottom-0 w-full bg-gray-600/50 z-50', {
    hidden: !open,
  });

  return (
    <div className={classes} onClick={onClose}>
      <div
        role="dialog"
        aria-modal="true"
        onClick={stopPropagation}
        className="fixed max-lg:bottom-0 w-full pb-6 px-5 pt-9 max-h-[70%] overflow-y-auto rounded-t-lg bg-white lg:w-1/2 lg:rounded-lg"
      >
        <div className="hidden absolute right-4 top-4 lg:block">
          <button type="button" onClick={onClose}>
            <IconClose />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
