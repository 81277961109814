import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Card = forwardRef(({ children, className, ...props }, ref) => {
  const classes = classnames('flex flex-col bg-white rounded-lg shadow-[0px_0px_4px_0px_rgba(0,0,0,0.20)]', className);

  return (
    <div className={classes} ref={ref} {...props}>
      {children}
    </div>
  );
});

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Card;
