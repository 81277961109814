import React, { Suspense } from 'react';
import { Await, Navigate, useLoaderData } from 'react-router';
import { Loading } from '../../../../components';

function Callback() {
  const loaderData = useLoaderData();

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={loaderData?.data}>
        {(data) => {
          if (data?.location) {
            return (
              <Navigate to={data.location} />
            );
          }

          return null;
        }}
      </Await>
    </Suspense>
  );
}

export { default as callbackLoader } from './loader';
export default Callback;
