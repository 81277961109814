import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import useStore from '../../../../store';
import getPath from '../../../../utils/path';

const selector = (state) => [state.results];

function useResults() {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [results] = useStore(selector);

  useEffect(() => {
    if (results === null) {
      navigate(getPath(lang, '/?error=invalid_request_data'));
    }
  }, [results]);

  return {
    results: results !== null ? results : {},
  };
}

export default useResults;
