import React, { Suspense, useEffect } from 'react';
import {
  Await,
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useNavigation,
  useParams,
} from 'react-router';
import { Footer, Header, Loading } from '../../components';
import Holding from './components/holding';
import useStore from '../../store';
import useTTL from '../../hooks/use-ttl';
import useReport from '../../hooks/use-report';

const selector = (state) => [state.session, state.setSession];

const Config = {
  COMING_SOON: import.meta.env.VITE_COMING_SOON,
  global: {
    footer: {
      content: [
        'footer.privacy.content.1',
        'footer.privacy.content.2',
        'footer.privacy.content.3',
        'footer.privacy.content.4',
        'footer.privacy.content.5',
      ],
    },
  },
  be: {
    footer: {
      content: [
        'be.footer.privacy.content.1',
        'be.footer.privacy.content.2',
        'be.footer.privacy.content.3',
        'be.footer.privacy.content.4',
        'be.footer.privacy.content.5',
        'be.footer.privacy.content.6',
      ],
    },
  },
};

// Wrapper component for all routes.
function Root() {
  const loaderData = useLoaderData();
  const navigation = useNavigation();
  const [session, setSession] = useStore(selector);
  const entity = import.meta.env.VITE_DMHI_ENTITY;
  const config = Config[entity === undefined ? 'global' : entity];

  useTTL();
  useEffect(() => {
    if (session === null) {
      const sessionId = window.crypto.randomUUID();

      setSession(sessionId);
    }
  }, []);

  // Start reporting - this monitors the URL to automatically report page loads.
  // Configure page load reporting.
  const { lang } = useParams();
  const matches = useMatches();
  const { Report, report } = useReport();

  useEffect(() => {
    const match = matches[matches.length - 1];
    const key = match?.handle?.key?.(match?.params ?? {});

    if (key) {
      report({
        action: Report.Actions.PAGE_LOAD,
      });
    }
  }, [matches, lang]);

  // The suspense pattern here is necessary to support the loading spinner on
  // initial page load (i.e. navigation into the app from outside of React
  // Router). The loading spinner once within the React Router context is
  // managed via the navigation state.
  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={loaderData?.data}>
        {(data) => {
          if (data?.location) {
            window.location = data.location.href;
            return null;
          }

          return (
            <div className="flex flex-col min-h-screen">
              <ScrollRestoration />
              {navigation.state === 'loading' ? <Loading /> : null}
              <Header />
              <div className="flex flex-1">
                {Config.COMING_SOON ? <Holding /> : <Outlet />}
              </div>
              {Config.COMING_SOON ? null : <Footer content={config.footer.content} />}
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
}

export { default as rootLoader } from './loader';
export default Root;
