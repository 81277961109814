import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function CardBody({ children, className }) {
  const classes = classnames('p-5', className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CardBody;
