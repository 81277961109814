import React, { forwardRef } from 'react';
import { useHref, useLinkClickHandler } from 'react-router';

const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;

const Link = forwardRef(
  (
    {
      onClick,
      replace = false,
      state,
      target,
      to,
      href,
      ...rest
    },
    ref,
  ) => {
    const toHref = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });
    let absoluteHref;

    if (typeof to === 'string' && ABSOLUTE_URL_REGEX.test(to)) {
      absoluteHref = to;
    }

    if (typeof href === 'string' && ABSOLUTE_URL_REGEX.test(href)) {
      absoluteHref = href;
    }

    return (
      <a
        {...rest}
        href={absoluteHref || toHref}
        onClick={(event) => {
          onClick?.(event, absoluteHref !== undefined);
          if (!event.defaultPrevented && absoluteHref === undefined) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  },
);

export default Link;
