import { redirect } from 'react-router';
import * as client from 'openid-client';
import { validLocales } from '../../i18n';

const requiresAuth = import.meta.env.VITE_AUTH_REQUIRED;

// Loader for the root route. If the tenant requires authentication we generate
// an authorization URL for OpenID Connect and redirect.
async function loader({ params, request }) {
  // Redirect invalid locales to the root, and secondary locales to preferred
  // locales.
  if (params.lang !== undefined) {
    const match = validLocales[params.lang.toLowerCase()];

    if (match === undefined) {
      return redirect('/');
    }

    if (match.redirect) {
      return redirect(`/${match.redirect}`);
    }
  }

  if (requiresAuth === undefined || requiresAuth === 'false') {
    return null;
  }

  const url = new URL(request.url);
  const accessToken = window.localStorage.getItem('access_token');
  const sub = window.localStorage.getItem('sub');
  const orgStored = window.sessionStorage.getItem('org');
  const orgParam = url.searchParams.get('org');
  let org = orgStored;
  let server;
  let clientId;
  let orgId;
  let audience;

  if (orgStored === undefined || orgStored === '' || (orgParam && orgStored !== orgParam)) {
    window.sessionStorage.setItem('org', orgParam ?? '');
    org = orgParam;
  }

  // Tenants can provide parameters to the service via the query string. The
  // query param should be named "params" and the value is stored in session
  // storage so we can access it for the duration of the session.
  const storedTenantParams = window.sessionStorage.getItem('tenant_params');

  if (storedTenantParams === null) {
    const tenantParams = url.searchParams.get('params') ?? url.searchParams.get('PARAMS');

    if (tenantParams !== null) {
      window.sessionStorage.setItem('tenant_params', tenantParams);
    }
  }

  // By default we use the auth server details for AXA from the env. Because
  // the app has been designed in a single tenant manner each AXA entity has
  // its own set of environment variables for its own specific deployment. If
  // an `org` param was set we use a different auth server. This is to allow
  // development and test environments to operate independently of AXA.
  switch (org) {
    case 'mindstep':
      server = new URL(import.meta.env.VITE_AUTH_HOST_MINDSTEP);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_MINDSTEP;
      orgId = import.meta.env.VITE_AUTH_ORG_ID_MINDSTEP;
      audience = import.meta.env.VITE_AUTH_AUDIENCE_MINDSTEP;
      break;
    default:
      server = new URL(import.meta.env.VITE_AUTH_HOST_AXA);
      clientId = import.meta.env.VITE_AUTH_CLIENT_ID_AXA;
      audience = import.meta.env.VITE_AUTH_AUDIENCE_AXA;
  }

  const config = await client.discovery(server, clientId);

  // If we already have an authorized user we can stop here. If necessary in the
  // future we can use `client.fetchUserInfo` to access details of the
  // authenticated user and put them in the store.
  //
  // If we already have a token stored we can check whether it's valid by
  // calling the userInfo endpoint of the OIDC provider. If that fails we
  // continue to obtain a new token.
  if (accessToken && sub) {
    let userInfo;

    try {
      userInfo = await client.fetchUserInfo(config, accessToken, sub);
    } catch (err) {
      // Ignore.
    }

    if (userInfo !== undefined) {
      return null;
    }
  }

  const codeVerifier = client.randomPKCECodeVerifier();
  window.sessionStorage.setItem('code_verifier', codeVerifier);

  const data = client.calculatePKCECodeChallenge(codeVerifier)
    .then((codeChallenge) => {
      const parameters = {
        scope: 'openid email offline_access',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        redirect_uri: import.meta.env.VITE_AUTH_REDIRECT_URL,
        audience,
      };

      if (orgId !== undefined) {
        parameters.organization = orgId;
      }

      if (config.serverMetadata().supportsPKCE()) {
        parameters.nonce = client.randomNonce();
        window.sessionStorage.setItem('nonce', parameters.nonce);
      }

      const redirectTo = client.buildAuthorizationUrl(config, parameters);

      return {
        location: redirectTo,
      };
    });

  return {
    data,
  };
}

export default loader;
