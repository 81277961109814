import React from 'react';
import { useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { BackButton, Heading } from '../../../../components';

const Config = {
  life_satisfaction: {
    icon: '/icon-library-life-satisfaction.png',
    content: [
      {
        type: 'h1',
        content: 'guide.life_satisfaction.title',
      },
      {
        type: 'h3',
        content: 'guide.life_satisfaction.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.1',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.2',
      },
      {
        type: 'h3',
        content: 'guide.life_satisfaction.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.3',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.4',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.5',
      },
      {
        type: 'h3',
        content: 'guide.life_satisfaction.subtitle.3',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.6',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.7',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.8',
      },
      {
        type: 'h3',
        content: 'guide.life_satisfaction.subtitle.4',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.9',
      },
      {
        type: 'p',
        content: 'guide.life_satisfaction.content.10',
      },
    ],
  },
  improve_my_mood: {
    icon: '/icon-library-improve-my-mood.png',
    content: [
      {
        type: 'h1',
        content: 'guide.improve_my_mood.title',
      },
      {
        type: 'h3',
        content: 'guide.improve_my_mood.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.1',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.2',
      },
      {
        type: 'ul',
        content: [
          'guide.improve_my_mood.list.1',
          'guide.improve_my_mood.list.2',
          'guide.improve_my_mood.list.3',
          'guide.improve_my_mood.list.4',
          'guide.improve_my_mood.list.5',
        ],
      },
      {
        type: 'h3',
        content: 'guide.improve_my_mood.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.3',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.4',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.5',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.6',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.7',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.8',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.9',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.10',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.11',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.12',
      },
      {
        type: 'p',
        content: 'guide.improve_my_mood.content.13',
      },
    ],
  },
  quality_connections: {
    icon: '/icon-library-quality-connections.png',
    content: [
      {
        type: 'h1',
        content: 'guide.quality_connections.title',
      },
      {
        type: 'h3',
        content: 'guide.quality_connections.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.1',
      },
      {
        type: 'h3',
        content: 'guide.quality_connections.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.2',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.3',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.4',
      },
      {
        type: 'h3',
        content: 'guide.quality_connections.subtitle.3',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.5',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.6',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.7',
      },
      {
        type: 'h3',
        content: 'guide.quality_connections.subtitle.4',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.8',
      },
      {
        type: 'p',
        content: 'guide.quality_connections.content.9',
      },
    ],
  },
  feel_more_in_control: {
    icon: '/icon-library-feel-more-in-control.png',
    content: [
      {
        type: 'h1',
        content: 'guide.feel_more_in_control.title',
      },
      {
        type: 'h3',
        content: 'guide.feel_more_in_control.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.1',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.2',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.3',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.4',
      },
      {
        type: 'h3',
        content: 'guide.feel_more_in_control.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.5',
      },
      {
        type: 'h3',
        content: 'guide.feel_more_in_control.subtitle.3',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.6',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.7',
      },
      {
        type: 'h3',
        content: 'guide.feel_more_in_control.subtitle.4',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.8',
      },
      {
        type: 'p',
        content: 'guide.feel_more_in_control.content.9',
      },
    ],
  },
  take_care_of_yourself: {
    icon: '/icon-library-take-care-of-yourself.png',
    content: [
      {
        type: 'h1',
        content: 'guide.take_care_of_yourself.title',
      },
      {
        type: 'h3',
        content: 'guide.take_care_of_yourself.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.1',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.2',
      },
      {
        type: 'h3',
        content: 'guide.take_care_of_yourself.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.3',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.4',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.5',
      },
      {
        type: 'h3',
        content: 'guide.take_care_of_yourself.subtitle.3',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.6',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.7',
      },
      {
        type: 'p',
        content: 'guide.take_care_of_yourself.content.8',
      },
    ],
  },
  financial_wellbeing: {
    icon: '/icon-library-financial-wellbeing.png',
    content: [
      {
        type: 'h1',
        content: 'guide.financial_wellbeing.title',
      },
      {
        type: 'h3',
        content: 'guide.financial_wellbeing.subtitle.1',
      },
      {
        type: 'p',
        content: 'guide.financial_wellbeing.content.1',
      },
      {
        type: 'h3',
        content: 'guide.financial_wellbeing.subtitle.2',
      },
      {
        type: 'p',
        content: 'guide.financial_wellbeing.content.2',
      },
      {
        type: 'h3',
        content: 'guide.financial_wellbeing.subtitle.3',
      },
      {
        type: 'p',
        content: 'guide.financial_wellbeing.content.3',
      },
      {
        type: 'p',
        content: 'guide.financial_wellbeing.content.4',
      },
      {
        type: 'h3',
        content: 'guide.financial_wellbeing.subtitle.4',
      },
      {
        type: 'p',
        content: 'guide.financial_wellbeing.content.5',
      },
    ],
  },
};

function Guide() {
  const { guide } = useParams();
  const { t } = useTranslation();
  const content = Config[guide];

  return (
    <div className="w-full mx-auto relative">
      <div className="w-full h-32 bg-axa-ocean-100 relative" />
      <div className="absolute w-full h-[160px] bg-contain bg-no-repeat bg-center mt-[-120px]" style={{ backgroundImage: `url(${content.icon})` }} />
      <div className="container mx-auto px-4 flex flex-col mt-14 md:max-w-[756px] lg:max-w-[957px]">
        {content.content.map((item) => {
          switch (item.type) {
            case 'h1':
            case 'h3':
              return (
                <Heading key={item.content} variant={item.type} className="mb-3 mt-3 first:mt-0">
                  <Trans i18nKey={item.content} />
                </Heading>
              );
            case 'p':
              return <p key={item.content} className="mb-4"><Trans i18nKey={item.content} /></p>;
            case 'ul':
              return (
                <ul key={item.content.join()} className="list-disc list-inside">
                  {item.content.map((l) => <li key={l} className="last:mb-3">{t(l)}</li>)}
                </ul>
              );
            default:
              return null;
          }
        })}
      </div>
      <div className="flex justify-center my-12">
        <BackButton id="back" />
      </div>
    </div>
  );
}

export default Guide;
