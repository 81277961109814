import React from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Heading } from '../../../../components';

import IconFlagBE from '../../../../assets/icon-flag-be.png';

const localServicesClassnames = {
  li: 'bg-white p-4',
};

const Config = {
  default: [
    {
      id: 'list-be',
      type: 'ul',
      content: [
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: IconFlagBE,
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'safeguarding.be.service.1.title',
              ],
            },
            'safeguarding.be.service.1.content',
          ],
        },
        {
          id: 'list-item-be',
          type: 'list-item-icon',
          icon: '/icon-support-be-1.png',
          classNames: localServicesClassnames,
          content: [
            {
              type: 'title',
              content: [
                'safeguarding.be.service.2.title',
              ],
            },
            'safeguarding.be.service.2.content',
          ],
        },
      ],
    },
  ],
};

function ContentRenderer({ content, className }) {
  const { t } = useTranslation();

  return content.map((item) => {
    switch (item.type) {
      case 'ul': {
        return (
          <ul key={item.id} className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-y-10">
            <ContentRenderer content={item.content} />
          </ul>
        );
      }
      case 'list-item':
        return (
          <li key={item.id} className="bg-white p-4">
            <ContentRenderer content={item.content} />
          </li>
        );
      case 'list-item-icon': {
        const liClasses = classnames('flex gap-4 only:col-span-2', item.classNames?.li);
        const imgClasses = classnames('w-[73px] h-[73px] bg-white rounded-full object-cover shadow', item.classNames?.img);

        return (
          <li key={item.id} className={liClasses}>
            <img src={item.icon} alt={item.alt} className={imgClasses} />
            <div className="flex flex-col flex-grow min-w-1">
              <ContentRenderer content={item.content} />
            </div>
          </li>
        );
      }
      case 'button-group':
        return (
          <div key={item.id} className="flex flex-col gap-3 justify-center mt-4 md:flex-row">
            <ContentRenderer content={item.content} className="first:order-2 md:last:order-2" />
          </div>
        );
      case 'button':
        return (
          <Button
            type={item.buttonType}
            to={item.href}
            variant={item.variant}
            size="sm"
            id={item.id}
            target={item.target}
            className={className}
          >
            {t(item.content)}
          </Button>
        );
      case 'title':
        return <div className="font-bold mt-3 first:mt-0"><ContentRenderer content={item.content} /></div>;
      default:
        return (
          <p className="mt-2 first:mt-0 break-words">
            <Trans
              i18nKey={item}
              components={{
                Link: <a className="text-axa-blue-500 underline" />,
              }}
            />
          </p>
        );
    }
  });
}

function BESupport() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const content = Config[lang?.toLowerCase?.()] || Config.default;

  return (
    <>
      <div className="flex flex-col items-center mx-auto text-center">
        <Heading variant="h2">{t('safeguarding.title')}</Heading>
        <p className="mt-2 text-axa-ocean-300">{t('safeguarding.lead')}</p>
      </div>
      <ContentRenderer content={content} />
      <div className="md:bg-white md:p-6">
        <Heading variant="h2">{t('safeguarding.be.doctor.title')}</Heading>
        <p className="mt-4">{t('safeguarding.be.doctor.content')}</p>
      </div>
    </>
  );
}

export default BESupport;
