import React from 'react';
import { useNavigate } from 'react-router';
import useTranslate from '../../../../hooks/use-translate';
import { Button, Heading } from '../../../../components';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import Item from '../../components/item';

function Information() {
  const navigate = useNavigate();
  const { trans, t } = useTranslate([
    'notice.content.2',
    'notice.content.5',
    'notice.content.14',
    'notice.content.24',
    'notice.content.27',
    'notice.content.31',
    'notice.content.36',
    'notice.content.47',
    'notice.content.61',
  ]);

  return (
    <div className="container mx-auto pt-8 md:pt-16 md:pb-16 px-4">
      <Button
        variant="link"
        onClick={(e) => { e.preventDefault(); navigate(-1); }}
        size="sm"
        className="!flex items-center gap-4"
        tabIndex="-1"
      >
        <IconNavigateBefore className="inline fill-gray-800" />
        {t('back')}
      </Button>

      <div className="flex flex-col">
        <Heading variant="h1" className="text-right !text-axa-blue-500 uppercase">{trans['notice.content.2']}</Heading>
        <div className="flex flex-col gap-4 bg-axa-green-50 p-3 mt-6">
          <p>{t('notice.content.3')}</p>
          <p>{t('notice.content.4')}</p>
          <p>{trans['notice.content.5']}</p>
        </div>
        <Heading variant="h2" className="text-right !text-axa-blue-500 uppercase mt-8">{t('notice.content.6')}</Heading>
        <div className="flex flex-col gap-6 mt-8">
          <Item title={t('notice.content.7')} content={[t('notice.content.8'), t('notice.content.9')]} />
          <Item title={t('notice.content.10')} content={[t('notice.content.11'), t('notice.content.12')]} />
          <Item title={t('notice.content.13')} content={[trans['notice.content.14']]} />
        </div>
        <div className="flex flex-col gap-6 mt-6 bg-axa-green-50 px-3 py-6">
          <Item
            title={t('notice.content.15')}
            content={[
              t('notice.content.16'), t('notice.content.17'),
              t('notice.content.18'),
              t('notice.content.19'),
              t('notice.content.20'),
              t('notice.content.21'),
              t('notice.content.22'),
              t('notice.content.23'),
              trans['notice.content.24'],
              t('notice.content.25'),
            ]}
          />
          <Item title={t('notice.content.26')} content={[trans['notice.content.27'], t('notice.content.28')]} />
          <Item title={t('notice.content.29')} content={[t('notice.content.30'), trans['notice.content.31']]} />
        </div>
        <div className="flex flex-col gap-6 mt-8">
          <Item title={t('notice.content.32')} content={[t('notice.content.33')]} />
          <Item title={t('notice.content.34')} content={[t('notice.content.35'), trans['notice.content.36'], t('notice.content.37'), t('notice.content.38')]} />
          <Item title={t('notice.content.39')} content={[t('notice.content.40'), t('notice.content.41'), t('notice.content.42'), t('notice.content.43')]} />
          <Item title={t('notice.content.44')} content={[t('notice.content.45')]} />
          <Item title={t('notice.content.46')} content={[trans['notice.content.47'], t('notice.content.48')]} />
          <Item title={t('notice.content.49')} content={[t('notice.content.50')]} />
          <Item title={t('notice.content.51')} content={[t('notice.content.52')]} />
          <Item title={t('notice.content.53')} content={[t('notice.content.54')]} />
          <Item title={t('notice.content.55')} content={[t('notice.content.56'), t('notice.content.57'), t('notice.content.58')]} />
          <Item title={t('notice.content.59')} content={[t('notice.content.60'), trans['notice.content.61'], t('notice.content.62')]} />
        </div>
      </div>
    </div>
  );
}

export default Information;
