import React from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Heading,
  Progress,
} from '../../../../components';
import { LayoutScreeningSection } from '../../../../layouts';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import useScreening from '../../hooks/use-screening';

function Section() {
  const { Screening } = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sectionIndex } = useParams();
  const { sections, section } = useScreening({
    Screening,
    sectionIndex,
    questionIndex: 0,
  });
  const total = sections.length;
  const current = parseInt(sectionIndex, 10);
  const progressLabel = t('section_card.progress', { current, total });
  const hero = (
    <div
      className="bg-cover bg-no-repeat h-[250px] w-full bg-center lg:h-auto"
      style={{ backgroundImage: `url(${section.background})` }}
    />
  );

  return (
    <LayoutScreeningSection hero={hero}>
      <div className="flex w-full max-w-[690px] flex-col gap-8 md:gap-12 mx-auto">
        <div className="w-full lg:px-5 lg:py-6 lg:shadow-sm lg:rounded lg:bg-white">
          <Progress total={total} current={current - 1} label={progressLabel} />
        </div>
        <div>
          <Heading variant="h1" className="text-center lg:text-3xl">{t(section.title)}</Heading>
          <p className="mt-7 text-left lg:text-xl">{t(section.content)}</p>
        </div>
        <ButtonGroup row>
          <Button
            variant="default"
            type="button"
            onClick={(e) => { e.preventDefault(); navigate(-1); }}
            size="sm"
            className="flex items-center gap-4 text-axa-blue-500"
            tabIndex="-1"
            id="back"
          >
            <IconNavigateBefore className="inline fill-axa-blue-500" />
            {t('back')}
          </Button>
          <Button
            type="link"
            to="q/1"
            variant="primary"
            id="continue"
          >
            {t('continue')}
          </Button>
        </ButtonGroup>
      </div>
    </LayoutScreeningSection>
  );
}

export default Section;
