// Utility function to get the correct URL path taking the currently
// selected language into account.
function getPath(lang, suffix) {
  let path = suffix;
  const segments = path.split('/');

  if (!suffix.startsWith('/')) {
    const url = new URL(window.location);

    path = `${url.pathname}/${suffix}`;
  } else if (typeof lang === 'string' && lang !== '' && segments[1] !== lang) {
    path = `/${lang}${suffix}`;
  }

  return path;
}

export default getPath;
