import { redirect } from 'react-router';
import useStore from '../../../../store';
import getPath from '../../../../utils/path';
import API from '../../../../services/api';

async function action({ params }) {
  const { assessmentId } = params;
  const url = assessmentId !== undefined ? `/assessment/${assessmentId}` : '/assessment/dmhi';
  const tenantParams = window.sessionStorage.getItem('tenant_params');
  const { responses, setResults } = useStore.getState();
  const payload = {
    assessmentId,
    responses,
  };

  if (tenantParams !== null) {
    payload.tenantParams = tenantParams;
  }

  // Submit the gathered responses and wait for results to come back from the
  // API.
  const res = await API.post(url, {
    body: payload,
  });

  // Update global store with the results returned by the API.
  setResults(res?.data?.score);

  // Redirect to the results page.
  const next = assessmentId !== undefined ? `/assessment/${assessmentId}/results` : '/results';

  return redirect(getPath(params.lang, next));
}

export default action;
