import React, { forwardRef } from 'react';
import { Card, CardBody, Radio } from '../../../../components';

function ResponseCard({
  id,
  content,
  handleResponse,
  promptId,
  selected,
  ...props
}, ref) {
  const handleChange = (e, value) => {
    handleResponse(value);
  };

  // If this response card has focus and the enter key or spacebar is pressed,
  // select this response.
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleResponse(id);
    }
  };

  // Special case for question A1 which has responses from 0 to 10 and is laid
  // out horizontally without radio buttons instead of vertically.
  if (promptId === 'A1') {
    return (
      <label htmlFor={id} onClick={(e) => handleChange(e, id)} className="basis-0 flex-grow text-center">
        <Card onKeyDown={handleKeyDown} className={`cursor-pointer outline-axa-blue-500 border-axa-blue-500 hover:outline hover:outline-2 ${selected ? '!bg-axa-blue-500/15' : ''}`} ref={ref} {...props}>
          <CardBody className="px-1 py-2 sm:px-2">
            <div className="flex justify-center cursor-pointer">
              {content}
            </div>
          </CardBody>
        </Card>
      </label>
    );
  }

  return (
    <label htmlFor={id} onClick={(e) => handleChange(e, id)}>
      <Card onKeyDown={handleKeyDown} className={`group cursor-pointer hover:outline hover:outline-2 hover:outline-axa-blue-500 ${selected ? '!bg-axa-blue-500/15' : ''}`} ref={ref} {...props}>
        <CardBody className="p-3">
          <div className="flex items-center justify-between cursor-pointer">
            {content}
            <div className="flex min-w-8 justify-end">
              <Radio
                name={promptId}
                id={id}
                value={id}
                defaultChecked={selected}
                tabIndex="-1"
                onClick={(e) => e.stopPropagation()}
                className="pointer-events-none cursor-pointer outline-axa-blue-500/50 group-hover:outline group-hover:outline-2 group-hover:border-axa-blue-500 group-focus:outline group-focus:outline-2 group-focus:border-axa-blue-500 rounded-full"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </label>
  );
}

export default forwardRef(ResponseCard);
