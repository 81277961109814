import React from 'react';
import { useLoaderData, useParams } from 'react-router';
import {
  Button,
  ButtonGroup,
  Hero,
  Type,
} from '../../../../components';
import { LayoutLanding } from '../../../../layouts';
import useTranslate from '../../../../hooks/use-translate';

function ScreeningIntro() {
  const { Screening } = useLoaderData();
  const { assessmentId } = useParams();
  const { trans, t } = useTranslate([
    Screening.intro.title,
    Screening.intro.content,
  ]);

  return (
    <LayoutLanding hero={<Hero img={Screening.intro.background} />}>
      <div className="flex flex-col gap-8 mx-auto md:gap-16 lg:gap-12 lg:max-w-[500px]">
        <h1 className="text-2xl font-bold text-center">
          {trans[Screening.intro.title]}
        </h1>
        <Type variant="lead">{trans[Screening.intro.content]}</Type>
        <ButtonGroup>
          <Button
            type="link"
            to="consent"
            variant="primary"
            id="start"
            state={{ next: `/assessment/${assessmentId}/start` }}
          >
            {t(Screening.intro.cta)}
          </Button>
        </ButtonGroup>
      </div>
    </LayoutLanding>
  );
}

export default ScreeningIntro;
