import useStore from '../../store';
import screeningsLoader from '../_loaders/screenings';

async function loader() {
  let res;

  try {
    res = await screeningsLoader();
  } catch (err) {
    return null;
  }

  useStore.getState().setScreenings(res?.screenings);
  return res;
}

export default loader;
