import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Progress } from '../../../../components';
import IconClock from '../../../../assets/icon-clock.svg?react';

function QuestionCard({
  question,
  image,
  prompt,
  timeframe,
  total,
  current,
}) {
  const { t } = useTranslation();
  const progressLabel = t('question_card.progress', { current, total });
  const questionClasses = classnames({
    'lg:text-xl': !prompt,
    'lg:text-lg': !!prompt,
  });

  return (
    <div className="flex flex-col gap-10 rounded-lg p-5 bg-white text-center min-h-[197px] lg:bg-transparent">
      <Progress total={total} current={current} label={progressLabel} />
      <div>
        <p className={questionClasses}>
          <Trans
            i18nKey={question}
            components={{
              Normal: <span className="font-normal" />,
              Bold: <span className="font-bold" />,
            }}
          />
        </p>
        {prompt && (
          <p className="mt-3 font-bold lg:text-xl lg:mt-6">
            <Trans
              i18nKey={prompt}
              components={{
                Normal: <span className="font-normal" />,
              }}
            />
          </p>
        )}
        {timeframe && (
          <div className="flex gap-3 items-center mt-3 md:mt-6 justify-center">
            <IconClock />
            <p className="text-xs text-gray-700">{timeframe}</p>
          </div>
        )}
      </div>
      {image && <img src={t(image)} alt="" />}
    </div>
  );
}

QuestionCard.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  timeframe: PropTypes.string,
  image: PropTypes.string,
  prompt: PropTypes.string,
};

export default QuestionCard;
