import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function PowerBar({
  active,
  score,
  variant = 'default',
  className,
}) {
  if (variant === 'mini') {
    const classes = classnames('rounded-full h-full', {
      'bg-axa-sienna-200': active === 0,
      'bg-axa-yellow-100': active === 1,
      'bg-axa-green-500': active === 2,
    });

    return (
      <div className="rounded-full h-1.5 w-full bg-gray-300">
        <div className={classes} style={{ width: `${score}%` }} />
      </div>
    );
  }

  const segments = [1, 2, 3, 4];
  const classes = classnames('flex items-center justify-center', className);

  return (
    <div className={classes}>
      {segments.map((segment, i) => {
        const classes = classnames('w-14 mr-0.5 last:mr-0', {
          'h-3': active !== i,
          'h-5': active === i,
          'bg-axa-sienna-200': i === 0,
          'bg-axa-yellow-100': i === 1,
          'bg-axa-green-100': i === 2,
          'bg-axa-green-500': i === 3,
        });

        return (
          <div key={segment} className={classes} />
        );
      })}
    </div>
  );
}

PowerBar.propTypes = {
  active: PropTypes.number,
  score: PropTypes.number,
  variant: PropTypes.oneOf([
    'default',
    'mini',
  ]),
};

export default PowerBar;
